import React from "react"
import { Layout } from "components/layout"
import { Seo } from "components/seo"
import { Breadcrumb } from "components/breadcrumb"
import HubspotForm from "react-hubspot-form"

const Apply = ({ location }) => {
  return (
    <Layout lang="zh-CN">
      <Seo
        pagetitle="应用"
        pagepath={location.pathname}
        pagedesc="应用 | PARKLoT提供了一个Twitter活动工具，可以 随心所欲 地运行，只需支付 月费。"
      />
      <Breadcrumb title="应用" />
      <main className="information">
        <section className="information__section l-container">
          {/* <h1 className="information__title"></h1> */}
          <HubspotForm
            portalId="8283520"
            formId="cd6d1560-bdc7-4169-9c55-640cc2a2500c"
            loading={<div>正在加载...</div>}
          />
        </section>
      </main>
    </Layout>
  )
}

export default Apply
